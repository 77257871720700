<template>
  <div class="page">
    <SearchBar
      placeholder="搜索歌曲、歌星"
    ></SearchBar>
    <div class="class-list">
      <div class="tabs">
        <div class="tabs-list">
          <div class="tab"
            v-for="(item, index) in classifyList"
            :key="index"
            :class="{ 'active': item.id === curClassify.id }"
            @click="handleChangeTab(item)">{{ item.name }}
          </div>
        </div>
      </div>
      <ClassifyPanel
        :ref="setPanelRefs"
        v-for="(item, index) in classifyList"
        v-show="item.id === curClassify.id"
        :key="index"
        :classify="item"
      ></ClassifyPanel>
    </div>
  </div>
</template>

<script>
import { getClassifyList } from '@/service/classify'
import { onBeforeMount, ref, computed } from 'vue'
import { useStore } from 'vuex'
import { onBeforeRouteLeave } from 'vue-router'
import ClassifyPanel from './components/panel.vue'
import _ from 'lodash'

export default {
  name: 'Classify',
  components: {
    ClassifyPanel,
  },
  activated () {
    const store = useStore()
    const {
      isUsed,
      position
    } = store.state.pageCacheData.classify
    if (!isUsed) {
      this.handleInitData()
    } else {
      // 统一转移到这里处理 - 防止刷新时多请求一次
      this.fetchList()
      this.$nextTick(() => {
        if ( _.has(this.getcurrPanelRefs(), 'loadMoreRef')) {
          this.getcurrPanelRefs().loadMoreRef.root.scrollTop = position
        }
        this.handleUpdateCachePosition(false, 0)
      })
    }
  },
  setup() {
    const store = useStore()
    let panelRefs = ref([])

    let classifyList = ref([])
    let curClassify = ref({
      id: 0,
      name: '',
      image: '',
      key:0
    });
    let changeTab = ref(false)
    let listSheet = ref({});
    let songList = ref([]);
    let isEmpty = computed(() => !!songList.value.length)

    const fetchList = async () => {
      classifyList.value = await getClassifyList()
      classifyList.value = classifyList.value.map((v, i) => {
        return {
          ...v,
          key: i
        }
      })
      if (classifyList.value.length) {
        curClassify.value = classifyList.value[0]
      }
    }

    const handleChangeTab = (tab) => {
      curClassify.value = tab
    }

    const handleInitData = () => {
      panelRefs.value = []
      classifyList.value = []
      curClassify.value = {
        id: 0,
        name: '',
        image: '',
        key:0
      }
      changeTab.value = false
      listSheet.value = {}
      songList.value = []
      fetchList()
    }

    const setPanelRefs = (e) => {
      if (e) {
        panelRefs.value.push(e)
      }
    }

    const getcurrPanelRefs = () => {
      return panelRefs.value.length ? panelRefs.value[curClassify.value.key] : {}
    }

    const handleUpdateCachePosition = (u, v) => {
      store.commit(
        'UPDATE_PAGE_CACHEDATA',
        {
          data: {
            isUsed: u,
            position: v
          },
          type: 'classify'
        }
      )
    }

    onBeforeRouteLeave((to, from, next) => {
      if (to.name === 'search') {
        const position = _.has(getcurrPanelRefs(), 'loadMoreRef') ? getcurrPanelRefs().loadMoreRef.root.scrollTop : 0
        handleUpdateCachePosition(true, position)
      }
      next()
    })

    return {
      classifyList,
      changeTab,
      listSheet,
      songList,
      curClassify,
      isEmpty,
      fetchList,
      handleChangeTab,
      handleInitData,
      setPanelRefs,
      getcurrPanelRefs,
      handleUpdateCachePosition,
    }
  },
}
</script>

<style lang="stylus" scoped>
.page
  height: 100vh
  overflow: hidden
  .class-list
    .tabs
      display flex
      align-items center
      width 100% !important
      height 100px
      padding 0 20px
      margin 10px 0 10px 0
      background #1E1F21
      border-radius 10px
      color rgba(255, 255, 255, 0.5)
      overflow-x scroll
      overflow-y hidden
      &::-webkit-scrollbar
        display: none
      &-list
        display flex
        align-items: center
        flex-wrap: nowrap
        height 100px
        &::-webkit-scrollbar
          display: none
      .tab
        display flex
        align-items center
        justify-content: center
        width 220px
        height 64px
        padding 0 26.66px
        color rgba(255, 255, 255, 0.5)
        font-size 32px
        line-height 40px
        text-align center
        margin-right 14px
      .active
        color #fff
        background rgba(255, 255, 255, 0.15)
        border-radius 10px
</style>
